<template>
  <div
    class="
      row
      no-gutters
      about-section
      services-section
      align-items-center
      services-animation5
    "
  >
    <div class="col-lg-8 position-relative mb-0 order-lg-1 order-1">
      <!-- <h1 class="services-section-index">5</h1> -->
      <h1>Graphics design</h1>
      <!-- <div class="dev-scroll"> -->
      <p>
        From professional logos to social media ads and print design, graphics
        are used in every advertising stage to create an awesome show for your
        target audience. But first you must choose your partner carefully from
        the overabundance of graphic design companies out there. So, take a look
        at our work and what could we do.
      </p>
      <!-- </div> -->
    </div>
    <div
      class="col-lg-4 order-lg-2 order-2 text-center"
      style="perspective: 600px"
    >
      <img
        src="../../assets/images/services/graphics.webp"
        alt="Developing mobile applications"
        class="services-section-image w-75"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Mobile",
  data() {
    return {};
  },
  methods: {},
};
</script>