<template>
  <div class="clients-section">
    <div class="space-ship">
      <ship-icon class="ship" />
      <svg
        class="ship-smoke"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="625.763"
        height="722.833"
        viewBox="0 0 625.763 722.833"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#fff" />
            <stop offset="1" stop-color="#fff" stop-opacity="0" />
          </linearGradient>
          <filter
            id="Path_49708"
            x="0"
            y="0"
            width="625.763"
            height="722.833"
            filterUnits="userSpaceOnUse"
          >
            <feOffset input="SourceAlpha" />
            <feGaussianBlur stdDeviation="10" result="blur" />
            <feFlood flood-color="#fff" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_49708)">
          <path
            id="Path_49708-2"
            data-name="Path 49708"
            d="M336.156-1.087c-.039-.28-28.94,8.737-54.3,8.508S233.92-1.41,233.867-1.087L0,621.867c186.02,53.529,377.311,52.79,565.763,0Z"
            transform="translate(30 31.1)"
            fill="url(#linear-gradient)"
          />
        </g>
      </svg>
    </div>
    <div class="clients-section_list">
      <img
        v-for="(item, index) in listClients"
        :key="index"
        :src="item.logo"
        :alt="item.name"
        v-if="item.isVisible"
        class="clients-section_list-item"
      />
    </div>
    <div class="clients-section_earth"></div>
  </div>
</template>

<script>
import { setTimeout } from "timers";

export default {
  name: "OurClients",
  data() {
    return {
      currentElement: null,
      listClients: [
        {
          id: 1,
          isVisible: false,
          name: "jubari",
          logo: require("../assets/images/clients/jubari.webp"),
        },
        {
          id: 2,
          isVisible: true,
          name: "kuraimi",
          logo: require("../assets/images/clients/kuraimi.webp"),
        },
        {
          id: 3,
          isVisible: false,
          name: "lens focus",
          logo: require("../assets/images/clients/lens_focus.webp"),
        },
        {
          id: 4,
          isVisible: false,
          name: "kuwait society",
          logo: require("../assets/images/clients/kuwait_society.webp"),
        },
        {
          id: 5,
          isVisible: false,
          name: "guide",
          logo: require("../assets/images/clients/guide.webp"),
        },

        {
          id: 6,
          isVisible: false,
          name: "jubran",
          logo: require("../assets/images/clients/jubran.webp"),
        },
        {
          id: 7,
          isVisible: false,
          name: "rolex",
          logo: require("../assets/images/clients/rolex.webp"),
        },
        {
          id: 8,
          isVisible: false,
          name: "laftah",
          logo: require("../assets/images/clients/laftah.webp"),
        },
        {
          id: 9,
          isVisible: false,
          name: "naqwa",
          logo: require("../assets/images/clients/naqwa.webp"),
        },
        {
          id: 10,
          isVisible: false,
          name: "muahal",
          logo: require("../assets/images/clients/muahal.png"),
        },
        {
          id: 11,
          isVisible: false,
          name: "speed",
          logo: require("../assets/images/clients/speed.png"),
        },
        {
          id: 12,
          isVisible: false,
          name: "gogreen",
          logo: require("../assets/images/clients/gogreen.png"),
        },
        {
          id: 13,
          isVisible: false,
          name: "nahdi",
          logo: require("../assets/images/clients/nahdi.png"),
        },
        {
          id: 14,
          isVisible: false,
          name: "najub",
          logo: require("../assets/images/clients/najub.png"),
        },


        {
          id: 15,
          isVisible: false,
          name: "tajer",
          logo: require("../assets/images/clients/tajer.png"),
        },
        {
          id: 16,
          isVisible: false,
          name: "halim",
          logo: require("../assets/images/clients/halim.png"),
        },
        {
          id: 17,
          isVisible: false,
          name: "halim-cars",
          logo: require("../assets/images/clients/halim-cars.png"),
        },
        {
          id: 18,
          isVisible: false,
          name: "bisha-factory",
          logo: require("../assets/images/clients/bisha-factory.png"),
        },

        {
          id: 19,
          isVisible: false,
          name: "lavania",
          logo: require("../assets/images/clients/lavania.png"),
        },
      ],
    };
  },
  computed: {},
  methods: {
    resetListClients() {
      for (let index = 0; index < this.listClients.length; index++) {
        var element = this.listClients[index];
        if (element.isVisible === true) {
          this.currentElement = element;
        }
      }
      if (this.currentElement) {
        var nextElement = this.listClients[this.currentElement.id];
        if (nextElement) {
          this.currentElement.isVisible = false;
          nextElement.isVisible = true;
        } else {
          this.currentElement.isVisible = false;
          this.currentElement = this.listClients[0];
          this.currentElement.isVisible = true;
        }
      }
    },
  },
  mounted() {
    setInterval(() => {
      this.resetListClients();
    }, 3000);
  },
};
</script>