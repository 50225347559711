<template>
  <div class="row no-gutters about-section services-section align-items-center services-animation6">
    <div class="col-lg-8 position-relative mb-0 order-lg-1 order-1">
      <!-- <h1 class="services-section-index">6</h1> -->
      <h1>E-marketing</h1>
      <!-- <div class="dev-scroll"> -->
      <p>
        In a world where virtually everyone relies on the Internet to find
        information, online marketing for business services has become a
        necessity for companies today. So, DevStation helps you grow your
        business through online marketing.
      </p>
      <!-- </div> -->
    </div>
     <div class="col-lg-4  order-lg-2 order-2 text-center" style="perspective: 600px">
      <img
        src="../../assets/images/services/marketing.webp"
        alt="E-marketing"
        class="services-section-image w-75"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Marketing",
  data() {
    return {};
  },
  methods: {},
};
</script>