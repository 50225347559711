<template>
  <div class="row no-gutters about-section about-animation3">
    <div class="col-lg-8 position-relative  order-lg-1 order-1">
      <label></label>
      <h1>Our Vision</h1>
      <p>
        Creating technical products that are the main destination for serving
        communities and the regional market.
      </p>
    </div>
      <div class="col-lg-4 text-center order-lg-2 order-2">
      <img
        src="../../assets/images/spaceman.webp"
        alt="SPACEMAN1"
        class="about-section-image"
        style="height:100%"
      />
      <!-- <person-icon /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "OurVision",
  data() {
    return {};
  },
  methods: {},
};
</script>