import Vue from 'vue'
import facebook from './facebook.svg'
import twitter from './twitter.svg'
import email from './email.svg'
import phone from './phone.svg'
import shipSmoke from './ship-smoke.svg'
import shipSmoke2 from './ship-smoke-2.svg'
import ship from './ship.svg'
import logo from './logo.svg'
import buttonMenu from './buttonMenu.svg'
import buttonMenuOpen from './buttonMenuOpen.svg'
import connectUs from './connect_us.svg'
import closeModal from './closeModal.svg'
import sendMessage from './sendMessage.svg'

Vue.component('facebook-icon', facebook)
Vue.component('twitter-icon', twitter)
Vue.component('email-icon', email)
Vue.component('phone-icon', phone)
Vue.component('ship-icon', ship)
Vue.component('ship-smoke-icon', shipSmoke)
Vue.component('ship-smoke-2-icon', shipSmoke2)
Vue.component('logo-icon', logo)
Vue.component('button-menu-icon', buttonMenu)
Vue.component('button-menu-open-icon', buttonMenuOpen)
Vue.component('connect-us-icon', connectUs)
Vue.component('close-modal-icon', closeModal)
Vue.component('send-message-icon', sendMessage)


