<template>
  <div class="row no-gutters about-section about-animation2">
    <div class="col-lg-8 position-relative  order-lg-1 order-1">
      <label></label>
      <h1>Our Message</h1>
      <p>
        Our products should be a technical companion in business development and
        facilitate access to services and products.
      </p>
    </div>
    <div class="col-lg-4 text-center order-lg-2 order-2">
      <img
        src="../../assets/images/spaceman-2.webp"
        alt="SPACEMAN1"
        class="about-section-image"
        style="height: 100%"
      />
      <!-- <person-icon /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "OurMessage",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>