<template>
  <div class="row no-gutters about-section services-section align-items-center services-animation2">
    <div class="col-lg-8 position-relative mb-0 order-lg-1 order-1">
      <!-- <h1 class="services-section-index">2</h1> -->
      <h1>Analyzing and developing systems</h1>
      <!-- <div class="dev-scroll"> -->
      <p>
        DevStation delivers a full-cycle software development services that
        adapt seamlessly to your project requirements and business needs.
      </p>
      <!-- </div> -->
    </div>
     <div class="col-lg-4  order-lg-2 order-2 text-center" style="perspective: 600px">
      <img
        src="../../assets/images/services/analyzing.webp"
        alt="Analyzing and developing systems"
        class="services-section-image w-75"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Analyzing",
  data() {
    return {};
  },
  methods: {},
};
</script>