<template>
  <div class="works_area scrollable-content">
    <div class="row no-gutters mt-5 w-100 mx-auto">
      <div
        class="col-lg-4 col-md-6 mt-5 mb-md-5 mb-0 d-flex justify-content-center align-items-center px-md-2 px-0"
        v-for="(item, index) in works"
        :key="index"
      >
        <picture
          :id="'work-' + item.id"
          @click="goToWorkUrl('work-' + item.id, item.url)"
        >
          <img :src="item.image" :alt="item.name" />
          <label class="go-to-work-url-icon"></label>
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurWorks",
  data() {
    return {
      works: [
        {
          id: 1,
          name: "Kuraimi Islamic Bank",
          image: require("../assets/images/works/kuraimi.webp"),
          url: "https://www.kibanks.com",
        },
        {
          id: 2,
          name: "Jubran Pharma",
          image: require("../assets/images/works/jubran_pharma.webp"),
          url: "https://www.jubranpharma.com/",
        },
        {
          id: 3,
          name: "Squizer System",
          image: require("../assets/images/works/squizer.webp"),
          url: "https://www.behance.net/gallery/147330683/Squizer-System?share=1",
        },
        {
          id: 4,
          name: "Squizer App",
          image: require("../assets/images/works/squizer_app.webp"),
          url: "#",
        },
        {
          id: 5,
          name: "Vi doctor",
          image: require("../assets/images/works/vi_doctor.webp"),
          url: "#",
        },
        {
          id: 6,
          name: "Visa Index",
          image: require("../assets/images/works/visaIndex.webp"),
          url: "#",
        },
        {
          id: 7,
          name: "Real Estate",
          image: require("../assets/images/works/real_estate.webp"),
          url: "https://www.behance.net/gallery/147330327/Real-estate?share=1",
        },

        {
          id: 8,
          name: "Ziad Wibsite",
          image: require("../assets/images/works/ziad.webp"),
          url: "http://ziadrashad.com/",
        },
        {
          id: 9,
          name: "Jubari System",
          image: require("../assets/images/works/Jubari.webp"),
          url: "https://www.behance.net/gallery/147329935/Jubari-System?share=1",
        },
        {
          id: 10,
          name: "Qomriah",
          image: require("../assets/images/works/qomriah.webp"),
          url: "https://www.behance.net/gallery/147329797/Qomriah-System?share=1",
        },
        {
          id: 11,
          name: "Jubari website",
          image: require("../assets/images/works/Jubari-website.webp"),
          url: "https://www.behance.net/gallery/147329589/Jubari-Website?share=1",
        },
      ],
    };
  },
  methods: {
    goToWorkUrl(id, url) {
      var element = document.getElementById(id);
      element.classList.add("go-to-work-url");
      setTimeout(() => {
        window.open(url, "_blank");
        element.classList.remove("go-to-work-url");
      }, 800);
    },
  },
  mounted() {},
};
</script>