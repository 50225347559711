<template>
  <div class="home-section">
    <div class="space-ship">
      <ship-icon class="ship" />
      <ship-smoke-icon class="ship-smoke" />
    </div>

    <h1>
      Welcome to
      <br class="d-lg-block d-none" />
      <span class="dev">Dev</span><span class="station">Station</span>'s space
      <br class="d-lg-block d-none" />
      where creativity is made
    </h1>
  </div>
</template>

<script>
import { TweenMax, gsap, TimelineMax, Power2, Power4 } from "gsap";

export default {
  name: "Home",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>