<template>
  <div class="position-relative w-100">
    <div id="container" dir="rtl">
      <div id="perspective">
        <div id="carousel">
          <div class="figure" v-for="(item, index) in teams" :key="index">
            <div class="employee-image">
              <img :src="item.image" :alt="item.name"/>
            </div>
            <div class="employee-data">
              <div>
                <h1>{{ item.name }}</h1>
                <p>{{ item.specialty }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-footer">
      <button class="btn prev">
        <svg
            id="Group_133057"
            data-name="Group 133057"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 31 31"
        >
          <g
              id="_chevron_right"
              data-name=" chevron_right"
              transform="translate(31.396 0) rotate(90)"
          >
            <rect
                id="Path"
                width="31"
                height="31"
                rx="4"
                transform="translate(0 0.396)"
                fill="#acb2bf"
                opacity="0"
            />
            <path
                id="Union_1"
                data-name="Union 1"
                d="M9937.492-22114.959l-6.757-6.475a1.363,1.363,0,0,1-.395-.955,1.321,1.321,0,0,1,.395-.943,1.369,1.369,0,0,1,1.888-.018l.011.006,5.8,5.574,5.818-5.58a1.362,1.362,0,0,1,1.882.018l.022.016a1.423,1.423,0,0,1,.361.945,1.3,1.3,0,0,1-.384.938l0,.012-6.746,6.463a1.311,1.311,0,0,1-.949.389A1.306,1.306,0,0,1,9937.492-22114.959Z"
                transform="translate(-9923.188 22136.152)"
                fill="#acb2bf"
            />
          </g>
        </svg>
      </button>
      <button class="btn next">
        <svg id="Group_133057" data-name="Group 133057" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31">
          <g
              id="_chevron_right"
              data-name=" chevron_right"
              transform="translate(31.396 0) rotate(90)"
          >
            <rect
                id="Path"
                width="31"
                height="31"
                rx="4"
                transform="translate(0 0.396)"
                fill="#acb2bf"
                opacity="0"
            />
            <path
                id="Union_1"
                data-name="Union 1"
                d="M9.028,8.76l6.757-6.475a1.363,1.363,0,0,0,.395-.955,1.321,1.321,0,0,0-.395-.943A1.369,1.369,0,0,0,13.9.369l-.011.006-5.8,5.574L2.266.369A1.362,1.362,0,0,0,.384.387L.361.4A1.423,1.423,0,0,0,0,1.348a1.3,1.3,0,0,0,.384.938l0,.012L7.135,8.76a1.347,1.347,0,0,0,1.894,0Z"
                transform="translate(23.333 21.584) rotate(180)"
                fill="#acb2bf"
            />
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurTeam",
  data() {
    return {
      teams: [
        {
          id: 1,
          name: "Salah Al-Waleedi",
          image: require("../assets/images/teams/salah.webp"),
          specialty: "Executive Director",
        },
        {
          id: 2,
          name: "Abdulrahman Jamal",
          image: require("../assets/images/teams/abdulrahman.webp"),
          specialty: "Backend Developer",
        },
        {
          id: 3,
          name: "Abdulrahman Al-sharie",
          image: require("../assets/images/teams/abdulrahman_alshari.webp"),
          specialty: "Mobile App Developer",
        },
        {
          id: 4,
          name: "Mansour Al-absi",
          image: require("../assets/images/teams/mansour.webp"),
          specialty: "Mobile App Developer",
        },
        {
          id: 5,
          name: "Abdullah Abdulrahman",
          image: require("../assets/images/teams/abdullah.webp"),
          specialty: "Mobile App Developer",
        },
        // {
        //   id: 6,
        //   name: "Hassan Saif",
        //   image: require("../assets/images/teams/hassan.webp"),
        //   specialty: "UI/UX Designer",
        // },
        {
          id: 7,
          name: "Asem Al-dobaei",
          image: require("../assets/images/teams/asim.webp"),
          specialty: "UI/UX Designer",
        },
        // {
        //   id: 8,
        //   name: "Yassin Al-humaidi",
        //   image: require("../assets/images/teams/yassin.webp"),
        //   specialty: "Frontend Developer",
        // },
        {
          id: 9,
          name: "Majid Al-Behsi",
          image: require("../assets/images/teams/majid.webp"),
          specialty: "Graphic designer",
        },
        {
          id: 10,
          name: "Rami Jabley",
          image: require("../assets/images/teams/rami.webp"),
          specialty: "Graphic designer",
        },
        {
          id: 11,
          name: "Mohammed Mansur",
          image: require("../assets/images/teams/mohammed_mansur.webp"),
          specialty: "Photographer",
        },
        // {
        //   id: 12,
        //   name: "Sabaa Al-Shamery",
        //   image: require("../assets/images/teams/sabaa.webp"),
        //   specialty: "Web Developer",
        // },
        {
          id: 13,
          name: "Sally alsubaihi",
          image: require("../assets/images/teams/sally.webp"),
          specialty: "Graphic designer",
        },
        {
          id: 14,
          name: "Nora Moghales",
          image: require("../assets/images/teams/nora.webp"),
          specialty: "Visual designer",
        },
        {
          id: 15,
          name: "Wafa Mohammed",
          image: require("../assets/images/teams/wafa.webp"),
          specialty: "Copy Writer",
        },
        {
          id: 16,
          name: "Hanan Abdullah",
          image: require("../assets/images/teams/hanan.webp"),
          specialty: "Graphic designer",
        },
        {
          id: 17,
          name: "Somiah Abdullah",
          image: require("../assets/images/teams/somiah.webp"),
          specialty: "Director of Marketing",
        },
        {
          id: 18,
          name: "Ziad Rashad",
          image: require("../assets/images/teams/ziad_rashad.webp"),
          specialty: "Full Stack Developer",
        },
      ],
    };
  },
  methods: {},
  mounted() {
    $("#carousel").carousel3d();
  },
};
</script>