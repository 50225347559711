<template>
  <div id="app">
    <div id="particles-js">
      <template v-if="isLoading">
        <div id="loading-animation" class="loading">
          <logo-icon class="loading-logo" />
          <div class="rocket">
            <div class="rocket-body">
              <div class="body"></div>
              <div class="fin fin-left"></div>
              <div class="fin fin-right"></div>
              <div class="window"></div>
            </div>
            <div class="exhaust-flame"></div>
            <ul class="exhaust-fumes">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </template>
      <template v-else>
        <Index msg="Welcome to DevStation website" />
      </template>
    </div>
  </div>
</template>

<script>
import Index from "./views/index.vue";
import { initParticles } from "./libs/animation";
export default {
  name: "App",
  components: {
    Index,
  },
  data() {
    return {
      isLoading: true,
      message: "",
    };
  },
  methods: {
    beforeUnloadListener(event) {
      if (event) {
        event.preventDefault();
        return (event.returnValue = "Are you sure you want to exit?");
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener("beforeunload", this.beforeUnloadListener());
    initParticles();
    if (this.isLoading == true) {
      setInterval(() => {
        var loadingElement = document.getElementById("loading-animation");
        if (loadingElement) {
          loadingElement.classList.toggle("run-loading");
        }
      }, 2500);
      setTimeout(() => {
        this.isLoading = false;
      }, 3500);
    }
  },
};
</script>