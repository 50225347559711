<template>
  <div class="row no-gutters about-section about-animation4 mt-cus-10">
    <div class="col-lg-8 position-relative order-lg-1 order-1">
      <label></label>
      <h1>Goals</h1>
      <ul>
        <li>
          Facilitate the services needed by communities through technology.
        </li>
        <li>Produce effective and easy-to-use software.</li>
        <li>
          Design suitable interfaces with user experience and take into account
          the technical awareness of communities on the technical side.
        </li>
        <li>Larger spread and high sales through e-marketing.</li>
      </ul>
    </div>
    <div class="col-lg-4 text-center order-lg-2 order-2">
      <img
        src="../../assets/images/spaceman-2.webp"
        alt="SPACEMAN1"
        class="about-section-image"
        style="height: 100%"
      />
      <!-- <person-icon /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Goals",
  data() {
    return {};
  },
  methods: {},
};
</script>