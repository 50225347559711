<template>
  <div class="connect-us-modal">
    <div class="connect-us-modal-header">
      <h1>Contact Us</h1>
      <close-modal-icon class="close" @click="hideConnectModal()" />
    </div>
    <div class="connect-us-modal-content">
      <b-form @submit.stop.prevent="onSubmit">
        <div class="row no-gutters w-100 m-0">
          <div class="col-md-12 my-md-12 my-0 mb-3 px-0">
            <b-form-input
              placeholder="Your Name"
              name="name"
              v-model="form.name"
              v-validate="{ required: true }"
              :state="validateState('name')"
              aria-describedby="input-1-live-feedback"
              data-vv-as="name"
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">{{
              veeErrors.first("name")
            }}</b-form-invalid-feedback>
          </div>
          <div class="col-md-12 my-md-12 my-0 mb-3 px-0">
            <b-form-input
              placeholder="example@gmail.com"
              name="email"
              v-model="form.email"
              v-validate="{ required: true, email: true }"
              :state="validateState('email')"
              aria-describedby="input-2-live-feedback"
              data-vv-as="email"
            ></b-form-input>
            <b-form-invalid-feedback id="input-2-live-feedback">{{
              veeErrors.first("email")
            }}</b-form-invalid-feedback>
          </div>
          <div class="col-md-12 my-md-12 my-0 mb-3 px-0">
            <b-form-input
              placeholder="Your Phone"
              name="phone"
              v-model="form.phone"
              v-validate="{ required: true, numeric: true, min: 6 }"
              :state="validateState('phone')"
              aria-describedby="input-3-live-feedback"
              data-vv-as="phone"
            ></b-form-input>
            <b-form-invalid-feedback id="input-3-live-feedback">{{
              veeErrors.first("phone")
            }}</b-form-invalid-feedback>
          </div>
          <div class="col-12 my-md-12 my-0 mb-md-0 mb-3 px-0">
            <b-form-textarea
              id="textarea"
              placeholder="Message"
              rows="5"
              no-resize
              name="message"
              v-model="form.message"
              v-validate="{ required: true }"
              :state="validateState('message')"
              aria-describedby="input-10-live-feedback"
              data-vv-as="Message"
            ></b-form-textarea>
            <b-form-invalid-feedback id="input-10-live-feedback">{{
              veeErrors.first("message")
            }}</b-form-invalid-feedback>
          </div>
          <div class="col-12 my-md-3 my-0 mb-md-0 mb-3 px-0">
            <b-overlay
              :show="spinning"
              opacity="0.6"
              spinner-small
              spinner-variant="secondary"
              class="d-inline-block w-100"
            >
              <b-button class="submit-form-button w-100" type="submit">
                <send-message-icon class="mx-1" />
                Send
              </b-button>
            </b-overlay>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "connect-us",
  components: {},
  data() {
    return {
      isSuccess: null,
      spinning: false,
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  methods: {
    hideConnectModal() {
      this.$emit("hideConnectModal", this.isSuccess);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        const data = {
          name: this.form.name,
          email: this.form.email,
          phone: this.form.phone,
          message: this.form.message,
        };
        this.spinning = true;
        this.$http
          .post("api/contact.php", data)
          .then((response) => {
            if (response.data.message_sent == true) {
              this.isSuccess = true;
              setTimeout(() => {
                this.spinning = false;
                this.hideConnectModal();
                this.form.name = "";
                this.form.email = "";
                this.form.phone = "";
                this.form.message = "";
              }, 2000);
            }
          })
          .catch(() => {
            this.isSuccess = false;
            setTimeout(() => {
              this.spinning = false;
              this.hideConnectModal();
            }, 2000);
          });
      });
    },
  },
};
</script>
