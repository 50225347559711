<template>
  <div class="row no-gutters about-section services-section align-items-center services-animation">
    <div class="col-lg-8 position-relative mb-0 order-lg-1 order-1">
      <!-- <h1 class="services-section-index">1</h1> -->
      <h1>Designing and building websites</h1>
      <!-- <div class="dev-scroll"> -->
      <p>
        At DevStation, we pride ourselves on creating websites that generate
        business with an impressive blend of technical expertise and website
        design flair. From quality website design and branding to the latest
        programming and functionality techniques we have the ability to provide
        the best solutions.
      </p>
      <!-- </div> -->
    </div>
      <div class="col-lg-4 order-lg-2 order-2 text-center" style="perspective: 600px">
      <img
        src="../../assets/images/services/analyzing.webp"
        alt="Designing and building websites"
        class="services-section-image w-75"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Designing",
  data() {
    return {};
  },
  methods: {},
};
</script>