import { TweenMax, gsap, TimelineMax, Power2, Power4 } from "gsap";

// After Load functions
export function afterLoadRock() {
  gsap.to(".rock", 0.8, {
    delay: 0.8,
    transform: "translateY(0)",
    opacity: 1,
    scale: 1.1,
  });
}
export function onLeaveRock() {
  gsap.from(".rock", 0.5, {
    delay: 0,
    transform: "translateY(200px)",
    opacity: 0,
    scale: 0,
  });
}
//

export function afterLoadScrollDown() {
  gsap.to(".scroll-down .ship-rocket", 0.5, {
    delay: 0.5,
    // transform: "translateY(0)",
    opacity: 1,
    // scale: 1,
  });
}
// Start Home Section
export function afterLoadHome() {
  var modal = document.getElementsByClassName("home")[0];
  modal.classList.add("active");
  gsap.to(".home-section h1", 1, {
    // delay: 0.3,
    scale: 1,
    opacity: 1,
  });
  gsap.to(".home-section h1 .dev", 0.8, {
    delay: 2,
    filter: "drop-shadow(0 0 5px #EA5A00)",
  });
  gsap.to(".home-section h1 .station", 0.8, {
    delay: 2,
    filter: "drop-shadow(0 0 5px #1790D2)",
  });
  gsap.to(".home-section .space-ship", 0.8, {
    delay: 1,
    transform: "translateY(0) rotate(0deg)",
    opacity: 1,
  });
  // gsap.to(".rock", 0.8, {
  //   delay: 0.8,
  //   transform: "translateY(0)",
  //   opacity: 1,
  //   scale: 1.1,
  // });
}
export function onLeaveHome() {
  var modal = document.getElementsByClassName("home")[0];
  modal.classList.remove("active");
  gsap.to(".home-section h1",  0.8, {
    // delay: 0,
    opacity: 0,
    scale: 0.5,
  });
  gsap.to(".home-section h1 .dev", 0.5, {
    // delay: 0,
    filter: "drop-shadow(0 0 0 #EA5A00)",
  });
  gsap.to(".home-section h1 .station", 0.5, {
    // delay: 0,
    filter: "drop-shadow(0 0 0 #1790D2)",
  });
  gsap.to(".home-section .space-ship", 0.5, {
    // delay: 0,
    transform: "translateY(-300px) rotate(-30deg)",
    opacity: 0,
  });
}
// End Home Section

// Start About Section
export function afterLoadAbout() {
  var modal = document.getElementsByClassName("about")[0];
  modal.classList.add("active");
  gsap.to(".about-animation h1", 0.8, {
    // delay: 0.5,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".about-animation p", 0.8, {
    delay: 0.2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".about-animation img", 1, {
    // delay: 1,
    transform: "translateY(0)",
    opacity: 1,
  });
  gsap.to(".about-animation label", 1, {
    // delay: 1,
    width: "60px",
    height: "60px",
    opacity: 1,
  });
}
export function onLeaveAbout() {
  var modal = document.getElementsByClassName("about")[0];
  modal.classList.remove("active");
  gsap.to(".about-animation h1", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".about-animation p", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".about-animation img", 0.5, {
    // delay: 0,
    transform: "translateY(500px)",
    opacity: 0,
  });
  gsap.to(".about-animation label", 0.5, {
    // delay: 0,
    width: "0",
    height: "0",
    opacity: 0,
  });
}
export function afterLoadOurMessage() {
  var modal = document.getElementsByClassName("about")[0];
  modal.classList.add("active");
  gsap.to(".about-animation2 h1", 0.8, {
    // delay: 0.5,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".about-animation2 p", 0.8, {
    delay: 0.2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".about-animation2 img", 1, {
    // delay: 1,
    transform: "translateY(0)",
    opacity: 1,
  });
  gsap.to(".about-animation2 label", 1, {
    // delay: 1,
    width: "60px",
    height: "60px",
    opacity: 1,
  });
}
export function onLeaveOurMessage() {
  var modal = document.getElementsByClassName("about")[0];
  modal.classList.remove("active");
  gsap.to(".about-animation2 h1", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".about-animation2 p", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".about-animation2 img", 0.5, {
    // delay: 0,
    transform: "translateY(500px)",
    opacity: 0,
  });
  gsap.to(".about-animation2 label", 0.5, {
    // delay: 0,
    width: "0",
    height: "0",
    opacity: 0,
  });
}
export function afterLoadOurVision() {
  var modal = document.getElementsByClassName("about")[0];
  modal.classList.add("active");
  gsap.to(".about-animation3 h1", 0.8, {
    // delay: 0.5,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".about-animation3 p", 0.8, {
    delay: 0.2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".about-animation3 img", 1, {
    // delay: 1,
    transform: "translateY(0)",
    opacity: 1,
  });
  gsap.to(".about-animation3 label", 1, {
    // delay: 1,
    width: "60px",
    height: "60px",
    opacity: 1,
  });
}
export function onLeaveOurVision() {
  var modal = document.getElementsByClassName("about")[0];
  modal.classList.remove("active");
  gsap.to(".about-animation3 h1", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".about-animation3 p", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".about-animation3 img", 0.5, {
    // delay: 0,
    transform: "translateY(500px)",
    opacity: 0,
  });
  gsap.to(".about-animation3 label", 0.5, {
    // delay: 0,
    width: "0",
    height: "0",
    opacity: 0,
  });
}
export function afterLoadGoals() {
  var modal = document.getElementsByClassName("about")[0];
  modal.classList.add("active");
  gsap.to(".about-animation4 h1", 0.8, {
    // delay: 0.5,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".about-animation4 ul li", 0.8, {
    delay: 0.2,
    transform: "translateX(0)",
    opacity: 1,
    stagger: 0.3,
  });
  gsap.to(".about-animation4 img", 1, {
    // delay: 1,
    transform: "translateY(0)",
    opacity: 1,
  });
  gsap.to(".about-animation4 label", 1, {
    // delay: 1,
    width: "60px",
    height: "60px",
    opacity: 1,
  });
}
export function onLeaveGoals() {
  var modal = document.getElementsByClassName("about")[0];
  modal.classList.remove("active");
  gsap.to(".about-animation4 h1", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".about-animation4 ul li", 0.5, {
    // delay: 0,
    opacity: 0,
    transform: "translateX(-500px)",
    stagger: 0,
  });
  gsap.to(".about-animation4 img", 0.5, {
    // delay: 0,
    transform: "translateY(500px)",
    opacity: 0,
  });
  gsap.to(".about-animation4 label", 0.5, {
    // delay: 0,
    width: "0",
    height: "0",
    opacity: 0,
  });
}
// End About Section

// Start Services Section
export function afterLoadServices() {
  var modal = document.getElementsByClassName("develop_websites")[0];
  modal.classList.add("active");
  gsap.to(".services-animation h1", 0.8, {
    // delay: 2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".services-animation p", 0.8, {
    delay: 0.2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".services-animation img", 1, {
    // delay: 1,
    transform: "translate(0px, 0px) rotateY(-30deg)",
    opacity: 1,
  });
  // gsap.to(".services-section img", 0.8, {
  //   // delay: 1,
  //   transform: "translate(0px, 0px) rotateY(-25deg)",
  // });
}
export function onLeaveServices() {
  var modal = document.getElementsByClassName("develop_websites")[0];
  modal.classList.remove("active");
  gsap.to(".services-animation h1", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".services-animation p", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".services-animation img", 0, {
    // delay: 0,
    transform: "translate(0px, 500px) rotateY(5deg)",
    opacity: 0,
  });
  // gsap.to(".services-animation img", 0, {
  //   // delay: 2,
  //   transform: "translate(0px, 500px) rotateY(0deg)",
  // });
}
export function afterLoadAnalyzing() {
  var modal = document.getElementsByClassName("develop_websites")[0];
  modal.classList.add("active");
  gsap.to(".services-animation2 h1", 0.8, {
    // delay: 2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".services-animation2 p", 0.8, {
    delay: 0.2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".services-animation2 img", 1, {
    // delay: 1,
    transform: "translate(0px, 0px) rotateY(-30deg)",
    opacity: 1,
  });
  // gsap.to(".services-animation2 img", 0.8, {
  //   // delay: 1,
  //   transform: "translate(0px, 0px) rotateY(-25deg)",
  // });
}
export function onLeaveAnalyzing() {
  var modal = document.getElementsByClassName("develop_websites")[0];
  modal.classList.remove("active");
  gsap.to(".services-animation2 h1", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".services-animation2 p", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".services-animation2 img", 0, {
    // delay: 0,
    transform: "translate(0px, 500px) rotateY(5deg)",
    opacity: 0,
  });
  // gsap.to(".services-animation2 img", 0, {
  //   // delay: 2,
  //   transform: "translate(0px, 500px) rotateY(0deg)",
  // });
}
export function afterLoadUXUI() {
  var modal = document.getElementsByClassName("develop_websites")[0];
  modal.classList.add("active");
  gsap.to(".services-animation3 h1", 0.8, {
    // delay: 2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".services-animation3 p", 0.8, {
    delay: 0.2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".services-animation3 img", 1, {
    // delay: 1,
    transform: "translate(0px, 0px) rotateY(-30deg)",
    opacity: 1,
  });
  // gsap.to(".services-animation3 img", 0.8, {
  //   // delay: 1,
  //   transform: "translate(0px, 0px) rotateY(-25deg)",
  // });
}
export function onLeaveUXUI() {
  var modal = document.getElementsByClassName("develop_websites")[0];
  modal.classList.remove("active");
  gsap.to(".services-animation3 h1", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".services-animation3 p", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".services-animation3 img", 0, {
    // delay: 0,
    transform: "translate(0px, 500px) rotateY(5deg)",
    opacity: 0,
  });
  // gsap.to(".services-animation3 img", 0, {
  //   // delay: 2,
  //   transform: "translate(0px, 500px) rotateY(0deg)",
  // });
}
export function afterLoadMobile() {
  var modal = document.getElementsByClassName("develop_websites")[0];
  modal.classList.add("active");
  gsap.to(".services-animation4 h1", 0.8, {
    // delay: 2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".services-animation4 p", 0.8, {
    delay: 0.2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".services-animation4 img", 1, {
    // delay: 1,
    transform: "translate(0px, 0px) rotateY(-30deg)",
    opacity: 1,
  });
  // gsap.to(".services-animation4 img", 0.8, {
  //   // delay: 1,
  //   transform: "translate(0px, 0px) rotateY(-25deg)",
  // });
}
export function onLeaveMobile() {
  var modal = document.getElementsByClassName("develop_websites")[0];
  modal.classList.remove("active");
  gsap.to(".services-animation4 h1", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".services-animation4 p", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".services-animation4 img", 0, {
    // delay: 0,
    transform: "translate(0px, 500px) rotateY(5deg)",
    opacity: 0,
  });
  // gsap.to(".services-animation4 img", 0, {
  //   // delay: 2,
  //   transform: "translate(0px, 500px) rotateY(0deg)",
  // });
}
export function afterLoadGraphics() {
  var modal = document.getElementsByClassName("develop_websites")[0];
  modal.classList.add("active");
  gsap.to(".services-animation5 h1", 0.8, {
    // delay: 2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".services-animation5 p", 0.8, {
    delay: 0.2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".services-animation5 img", 1, {
    // delay: 1,
    transform: "translate(0px, 0px) rotateY(-30deg)",
    opacity: 1,
  });
  // gsap.to(".services-animation5 img", 0.8, {
  //   // delay: 1,
  //   transform: "translate(0px, 0px) rotateY(-25deg)",
  // });
}
export function onLeaveGraphics() {
  var modal = document.getElementsByClassName("develop_websites")[0];
  modal.classList.remove("active");
  gsap.to(".services-animation5 h1", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".services-animation5 p", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".services-animation5 img", 0, {
    // delay: 0,
    transform: "translate(0px, 500px) rotateY(5deg)",
    opacity: 0,
  });
  // gsap.to(".services-animation5 img", 0, {
  //   // delay: 2,
  //   transform: "translate(0px, 500px) rotateY(0deg)",
  // });
}
export function afterLoadMarketing() {
  var modal = document.getElementsByClassName("develop_websites")[0];
  modal.classList.add("active");
  gsap.to(".services-animation6 h1", 0.8, {
    // delay: 2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".services-animation6 p", 0.8, {
    delay: 0.2,
    transform: "translateX(0)",
    opacity: 1,
  });
  gsap.to(".services-animation6 img", 1, {
    // delay: 1,
    transform: "translate(0px, 0px) rotateY(-30deg)",
    opacity: 1,
  });
  // gsap.to(".services-animation6 img", 0.8, {
  //   // delay: 1,
  //   transform: "translate(0px, 0px) rotateY(-25deg)",
  // });
}
export function onLeaveMarketing() {
  var modal = document.getElementsByClassName("develop_websites")[0];
  modal.classList.remove("active");
  gsap.to(".services-animation6 h1", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".services-animation6 p", 0.5, {
    // delay: 0,
    transform: "translateX(-500px)",
    opacity: 0,
  });
  gsap.to(".services-animation6 img", 0, {
    // delay: 0,
    transform: "translate(0px, 500px) rotateY(5deg)",
    opacity: 0,
  });
  // gsap.to(".services-animation6 img", 0, {
  //   // delay: 2,
  //   transform: "translate(0px, 500px) rotateY(0deg)",
  // });
}
// End Services Section

// Start Works Section
export function worksRandomSlide() {
  var modal = document.getElementsByClassName("our_works")[0];
  modal.classList.add("active");
  var posX = window.innerWidth - 600;
  var posY = window.innerHeight;
  var posXH = window.innerWidth / 2;
  var posYH = window.innerHeight / 2;
  const timeline = new TimelineMax();
  timeline.to(".works_area picture", 0.8, {
    delay: 0,
    opacity: 1,
    transform: "translateY(0)",
    ease: Power2.easeOn,
    stagger: 0.3,
  });
}
export function worksRandomSlideOnLeave() {
  var modal = document.getElementsByClassName("our_works")[0];
  modal.classList.remove("active");
  var posX = window.innerWidth - 500;
  var posY = window.innerHeight - 350;
  var posXH = window.innerWidth / 2;
  var posYH = window.innerHeight / 2;
  const timeline = new TimelineMax();
  timeline.to(".works_area picture", 0, {
    // delay: 0,
    opacity: 0,
    transform: "translateY(200px)",
    stagger: 0,
  });
}
// End Works Section

// Start Team Section
export function afterLoadTeam() {
  var modal = document.getElementsByClassName("our_team")[0];
  modal.classList.add("active");
  gsap.to("#perspective", 1.5, {
    delay: 0,
    opacity: 1,
    transform: "translateY(0)",
  });
}
export function onLeaveTeam() {
  var modal = document.getElementsByClassName("our_team")[0];
  modal.classList.remove("active");
  gsap.to("#perspective", 0, {
    // delay: 0,
    opacity: 0,
    transform: "translateY(200px)",
  });
}
// End Team Section

// Start Clients Section
export function afterLoadClients() {
  var modal = document.getElementsByClassName("our_clients")[0];
  modal.classList.add("active");
  gsap.to(".clients-section", 0.8, {
    // delay: 1,
    transform: "translateY(0)",
    opacity: 1,
  });
  gsap.to(".clients-section .space-ship", 2, {
    // delay: 1,
    transform: "translateY(0)",
    opacity: 1,
  });
  gsap.to(".clients-section .space-ship .ship-smoke", 3, {
    // delay: 1.5,
    height: "450px",
    opacity: 1,
  });
  gsap.to(".clients-section .clients-section_list", 2, {
    delay: 4,
    opacity: 1,
  });
}
export function onLeaveClients() {
  var modal = document.getElementsByClassName("our_clients")[0];
  modal.classList.remove("active");
  gsap.to(".clients-section", 0, {
    // delay: 0,
    transform: "translateY(200px)",
    opacity: 0,
  });
  gsap.to(".clients-section .space-ship", 0, {
    // delay: 0,
    transform: "translateY(-800px)",
    opacity: 0,
  });
  gsap.to(".clients-section .space-ship .ship-smoke", 0, {
    // delay: 0,
    height: 0,
    opacity: 0,
  });
  gsap.to(".clients-section .clients-section_list", 0, {
    // delay: 0,
    opacity: 0,
  });
}
// End Clients Section

// On Leave functions

export function onLeaveScrollDown() {
  gsap.to(".scroll-down .ship-rocket", 0, {
    delay: 0,
    // transform: "translateY(-50px)",
    opacity: 0.3,
    // scale: 0,
  });
}

// InitParticles
export function initParticles() {
  window.particlesJS("particles-js", {
    particles: {
      number: {
        value: 355,
        density: {
          enable: true,
          value_area: 789.1476416322727,
        },
      },
      color: {
        value: "#ffffff",
      },
      shape: {
        type: "circle",
        stroke: {
          width: 0,
          color: "#000000",
        },
        polygon: {
          nb_sides: 5,
        },
        image: {
          src: "img/github.svg",
          width: 100,
          height: 100,
        },
      },
      opacity: {
        value: 0.48927153781200905,
        random: false,
        anim: {
          enable: true,
          speed: 0.2,
          opacity_min: 0,
          sync: false,
        },
      },
      size: {
        value: 2,
        random: true,
        anim: {
          enable: true,
          speed: 2,
          size_min: 0,
          sync: false,
        },
      },
      line_linked: {
        enable: false,
        distance: 150,
        color: "#ffffff",
        opacity: 0.4,
        width: 1,
      },
      move: {
        enable: true,
        speed: 0.2,
        direction: "none",
        random: true,
        straight: false,
        out_mode: "out",
        bounce: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200,
        },
      },
    },
    interactivity: {
      detect_on: "canvas",
      events: {
        onhover: {
          enable: true,
          mode: "bubble",
        },
        onclick: {
          enable: true,
          mode: "push",
        },
        resize: true,
      },
      modes: {
        grab: {
          distance: 400,
          line_linked: {
            opacity: 1,
          },
        },
        bubble: {
          distance: 83.91608391608392,
          size: 1,
          duration: 3,
          opacity: 1,
          speed: 3,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
        push: {
          particles_nb: 4,
        },
        remove: {
          particles_nb: 2,
        },
      },
    },
    retina_detect: true,
  });
}

// After Loading
export function afterLoading() {
  const timeline = new TimelineMax();
  timeline.to("#menu li", 0.5, {
    // delay: 0,
    opacity: 1,
    transform: "translateY(0)",
    stagger: 0.1,
  });
  timeline.to(".social-media-links a", 0.5, {
    // delay: 0,
    opacity: 1,
    transform: "translateY(0)",
    stagger: 0.1,
  });
  gsap.to(".footer-copyright p", 0.5, {
    delay: 1,
    opacity: 1,
  });
  gsap.to(".connect-us", 0.8, {
    delay: 1,
    opacity: 1,
  });
}

// After Close Menu
export function afterCloseMenu() {
  const timeline = new TimelineMax();
  timeline.to("#menu li", 0.5, {
    // delay: 0,
    opacity: 0,
    transform: "translateY(200px)",
    stagger: {
      each: -0.2,
    },
  });
  timeline.to(".social-media-links a", 0.5, {
    // delay: 0,
    opacity: 0,
    transform: "translateY(200px)",
    stagger: {
      each: -0.2,
    },
  });
  gsap.to(".footer-copyright p", 0.5, {
    delay: 1,
    opacity: 0,
  });
}
