import Vue from "vue";
import {
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
  BOverlay,
  BAlert,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import App from "./App.vue";
import "./assets/svg/index";
import "./libs/3dcarousel";
import "bootstrap/dist/css/bootstrap.css";
import "./libs/particles.js";
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
import VueFullPage from 'vue-fullpage.js'
import axios from "axios";

Vue.use(VueFullPage);
import VeeValidate from "vee-validate";
Vue.prototype.$http = axios;
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors",
});
// import assets styles
require("@/assets/scss/style.scss");
Vue.component("b-alert", BAlert);
Vue.component("b-form-input", BFormInput);
Vue.component("b-form-textarea", BFormTextarea);
Vue.component("b-form", BForm);
Vue.component("b-button", BButton);
Vue.component("b-overlay", BOverlay);
Vue.component("b-form-invalid-feedback", BFormInvalidFeedback);

// Vue.config.performance = true;
// Vue.config.productionTip = false;
// Vue.config.devtools = true;
new Vue({
  render: (h) => h(App),
  mounted() {
    this.$validator.localize("en", {
      attributes: {
        email: "Email",
      },
    });
  },
}).$mount("#app");
