<template>
  <div>
    <img src="../assets/logo.webp" alt="Devstation logo" class="logo" />
    <div id="button-menu" class="button-menu">
      <button-menu-icon class="button-menu-close" @click="openMenu" />
      <button-menu-open-icon class="button-menu-open" @click="openMenu" />
    </div>
    <button
      @click="scrollToDown()"
      class="scroll-down"
      v-show="destinationIndex != 13"
    >
      <img
        src="../assets/images/ship-rocket.png"
        alt="ship rocket"
        class="ship-rocket"
      />
      <img
        src="../assets/images/rocket-&-planet.png"
        alt="rocket"
        class="rocket"
      />
      <span class="d-lg-block d-none">Scroll to Explore</span>
    </button>
    <headerPage @closeMenu="openMenu" />
    <full-page :options="options" id="fullpage" ref="fullpage">
      <div class="section" data-anchor="home">
        <Home />
      </div>
      <div class="section" data-anchor="about">
        <About />
      </div>
      <div class="section">
        <OurMessage />
      </div>
      <div class="section">
        <OurVision />
      </div>
      <div class="section">
        <Goals />
      </div>
      <div class="section" data-anchor="develop_websites">
        <Designing />
      </div>
      <div class="section">
        <Analyzing />
      </div>
      <div class="section">
        <UXUI />
      </div>
      <div class="section">
        <Mobile />
      </div>
      <div class="section">
        <Graphics />
      </div>
      <div class="section">
        <Marketing />
      </div>
      <div class="section isWorks" data-anchor="our_works">
        <OurWorks />
      </div>
      <div class="section" data-anchor="our_team">
        <OurTeam />
      </div>
      <div class="section our_clients" data-anchor="our_clients">
        <OurClients />
      </div>
    </full-page>
    <footerPage id="footer" />
    <label id="connect-us" class="connect-us" v-if="!isOpenMenu">
      <connect-us-icon class="connect-us-button" @click="showConnectModal()" />
      <connectUsModal @hideConnectModal="hideConnectModal" />
      <b-alert
        :show="dismissCountDownSuccess"
        dismissible
        variant="success"
        @dismissed="dismissCountDownSuccess = -1"
        @dismiss-count-down="countDownChangedSuccess"
      >
        <p>Your request has been sent, you will be answered shortly</p>
      </b-alert>
      <b-alert
        :show="dismissCountDownWarning"
        dismissible
        variant="warning"
        @dismissed="dismissCountDownWarning = -1"
        @dismiss-count-down="countDownChangedWarning"
      >
        <p>
          There is a problem with sending. If the problem persists, contact us
          via email or phone number
        </p>
      </b-alert>
    </label>
    <div class="animationHeader">
      <div class="rock" id="one"></div>
      <div class="rock" id="two"></div>
      <div class="rock" id="three"></div>
      <div class="rock" id="four"></div>
      <div class="rock" id="five"></div>
      <div class="rock" id="six"></div>
      <div class="rock" id="seven"></div>
      <div class="rock" id="eight"></div>
      <div class="rock d-lg-block d-none" id="nine"></div>
      <div class="rock" id="ten"></div>
      <div class="rock d-lg-block d-none" id="eleven"></div>
      <div class="rock d-lg-block d-none" id="twelve"></div>
    </div>
  </div>
</template>
<script>
import { TweenMax, gsap, TimelineMax, Power2, Power4 } from "gsap";
import * as Animation from "../libs/animation";
import ScrollTrigger from "gsap/ScrollTrigger";
import headerPage from "../components/Header.vue";
import footerPage from "../components/Footer.vue";
import Home from "../components/Home.vue";
import About from "../components/about/About.vue";
import OurMessage from "../components/about/OurMessage.vue";
import OurVision from "../components/about/OurVision.vue";
import Goals from "../components/about/Goals.vue";
import Designing from "../components/our_services/Designing.vue";
import Analyzing from "../components/our_services/Analyzing.vue";
import UXUI from "../components/our_services/UXUI.vue";
import Mobile from "../components/our_services/Mobile.vue";
import Graphics from "../components/our_services/Graphics.vue";
import Marketing from "../components/our_services/Marketing.vue";
import OurWorks from "../components/OurWorks.vue";
import OurTeam from "../components/OurTeam.vue";
import OurClients from "../components/OurClients.vue";
import connectUsModal from "../components/connectUsModal.vue";

export default {
  name: "App",
  components: {
    headerPage,
    footerPage,
    Home,
    About,
    OurMessage,
    OurVision,
    Goals,
    Designing,
    Analyzing,
    UXUI,
    Mobile,
    Graphics,
    Marketing,
    OurWorks,
    OurTeam,
    OurClients,
    connectUsModal,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      dismissSecsSuccess: 5,
      dismissCountDownSuccess: 0,
      dismissSecsWarning: 5,
      dismissCountDownWarning: 0,
      options: {
        licenseKey: "63ADD441-9A054545-9ABDF4E4-59EC4269",
        anchors: [
          "home",
          "about",
          "our_message",
          "our_vision",
          "goals",
          "develop_websites",
          "analyzing",
          "designing_uxui",
          "graphics_design",
          "develop_mobile_app",
          "e_marketing",
          "our_works",
          "our_team",
          "our_clients",
        ],

        navigation: false,
        menu: "#menu",
        afterLoad: this.afterLoad,
        onLeave: this.onLeave,
        normalScrollElements: ".scrollable-content",
        // lazyLoading: true,
        // autoScrolling: true,
        // scrollOverflow: true,
      },
      originDef: null,
      destinationDef: null,
      directionDef: null,
      destinationIndex: 0,
      menu_toggel: false,
      isOpenMenu: false,
    };
  },

  methods: {
    maxWidth() {
      // if (window.innerWidth < 1030) {
      //   // this.options.scrollBar = true;
      //   // this.options.autoScrolling = false;
      //   // this.options.scrollOverflow = false;
      // }
    },
    countDownChangedSuccess(dismissCountDownSuccess) {
      this.dismissCountDownSuccess = dismissCountDownSuccess;
    },
    countDownChangedWarning(dismissCountDownWarning) {
      this.dismissCountDownWarning = dismissCountDownWarning;
    },
    showConnectModal() {
      var modal = document.getElementById("connect-us");
      modal.classList.add("open-modal");
    },
    hideConnectModal(e) {
      var modal = document.getElementById("connect-us");
      modal.classList.remove("open-modal");
      if (e == true) {
        this.dismissCountDownSuccess = this.dismissSecsSuccess;
      } else if (e == false) {
        this.dismissCountDownWarning = this.dismissSecsWarning;
      }
    },
    openMenu() {
      if (window.innerWidth < 1030) {
        if (!this.menu_toggel) {
          this.menu_toggel = true;
          this.isOpenMenu = true;
          var element = document.getElementById("button-menu");
          var footer = document.getElementById("footer");
          var menu = document.getElementById("menu");
          var particles = document.getElementById("particles-js");
          menu.classList.add("open-menu");
          element.classList.add("open-menu");
          footer.classList.add("open-menu");
          particles.classList.add("open-menu");
          Animation.afterLoading();
        } else if (this.menu_toggel) {
          Animation.afterCloseMenu();
          setTimeout(() => {
            var element = document.getElementById("button-menu");
            var footer = document.getElementById("footer");
            var menu = document.getElementById("menu");
            var particles = document.getElementById("particles-js");
            menu.classList.remove("open-menu");
            element.classList.remove("open-menu");
            footer.classList.remove("open-menu");
            particles.classList.remove("open-menu");
            this.menu_toggel = false;
            this.isOpenMenu = false;
          }, 2000);
        }
      }
    },
    scrollToDown() {
      fullpage_api.moveSectionDown();
    },
    afterLoad(origin, destination, direction) {
      if (destination.index === 0) {
        this.destinationIndex = 0;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.afterLoadHome();
      }
      if (destination.index === 1) {
        this.destinationIndex = 1;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.afterLoadAbout();
      }
      if (destination.index === 2) {
        this.destinationIndex = 2;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.afterLoadOurMessage();
      }
      if (destination.index === 3) {
        this.destinationIndex = 3;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.afterLoadOurVision();
      }
      if (destination.index === 4) {
        this.destinationIndex = 4;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.afterLoadGoals();
      }
      if (destination.index === 5) {
        this.destinationIndex = 5;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.afterLoadServices();
      }
      if (destination.index === 6) {
        this.destinationIndex = 6;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.afterLoadAnalyzing();
      }
      if (destination.index === 7) {
        this.destinationIndex = 7;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.afterLoadUXUI();
      }
      if (destination.index === 8) {
        this.destinationIndex = 8;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.afterLoadMobile();
      }
      if (destination.index === 9) {
        this.destinationIndex = 9;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.afterLoadGraphics();
      }
      if (destination.index === 10) {
        this.destinationIndex = 10;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.afterLoadMarketing();
      }
      if (destination.index === 11) {
        this.destinationIndex = 11;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.worksRandomSlide();
      }
      if (destination.index === 12) {
        this.destinationIndex = 12;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.afterLoadTeam();
      }
      if (destination.index === 13) {
        this.destinationIndex = 13;
        Animation.afterLoadRock();
        Animation.afterLoadScrollDown();
        Animation.afterLoadClients();
      }
    },
    onLeave(origin, destination, direction) {
      if (origin) {
        Animation.onLeaveRock();
        Animation.onLeaveScrollDown();
        Animation.onLeaveHome();
        Animation.onLeaveAbout();
        Animation.onLeaveOurMessage();
        Animation.onLeaveOurVision();
        Animation.onLeaveGoals();
        Animation.onLeaveServices();
        Animation.onLeaveAnalyzing();
        Animation.onLeaveUXUI();
        Animation.onLeaveMobile();
        Animation.onLeaveGraphics();
        Animation.onLeaveMarketing();
        Animation.worksRandomSlideOnLeave();
        Animation.onLeaveTeam();
        Animation.onLeaveClients();
      }
    },
  },
  mounted() {
    // this.maxWidth();
    // fullpage_api.setScrollingSpeed(700);
    if (window.innerWidth > 1030) {
      Animation.afterLoading();
    }
    Animation.initParticles();
  },
};
</script>