<template>
  <div class="row no-gutters about-section services-section align-items-center services-animation4">
    <div class="col-lg-8 position-relative mb-0 order-lg-1 order-1">
      <!-- <h1 class="services-section-index">4</h1> -->
      <h1>Developing mobile applications</h1>
      <!-- <div class="dev-scroll"> -->
      <p>
        At DevStation, we offer a full cycle of application design (Android or
        iOS). Whether it is a consumer oriented app or a transformative
        enterprise-class solution, DevStation leads the entire mobile app
        development process from ideation and concept to delivery, and to
        ongoing support.
      </p>
      <!-- </div> -->
    </div>
    <div class="col-lg-4 order-lg-2 order-2 text-center" style="perspective: 600px">
      <img
        src="../../assets/images/services/mobile.webp"
        alt="mobile"
        class="services-section-image w-75"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Graphics",
  data() {
    return {};
  },
  methods: {},
};
</script>