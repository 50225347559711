<template>
  <ul id="menu">
    <li class="home active" @click="closeMenu()" data-menuanchor="home">
      <a href="#home">Home</a>
    </li>
    <li class="about" @click="closeMenu()" data-menuanchor="about">
      <a href="#about">About Us</a>
    </li>
    <li class="develop_websites" @click="closeMenu()" data-menuanchor="develop_websites">
      <a href="#develop_websites">Our Services</a>
    </li>
    <li class="our_works" @click="closeMenu()" data-menuanchor="our_works">
      <a href="#our_works">Our Works</a>
    </li>
    <li class="our_team" @click="closeMenu()" data-menuanchor="our_team">
      <a href="#our_team">Our Team</a>
    </li>
    <li class="our_clients" @click="closeMenu()" data-menuanchor="our_clients">
      <a href="#our_clients">Our Clients</a>
    </li>
  </ul>
</template>
<script>
export default {
  methods: {
    closeMenu() {
      // setTimeout(() => {
        this.$emit("closeMenu");
      // }, 100);
    },
  },
};
</script>