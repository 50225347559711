<template>
  <div class="row no-gutters about-section about-animation mt-cus-10">
    <div class="col-lg-8 position-relative order-lg-1 order-1">
      <label></label>
      <h1>Who are we</h1>
      <p>
        DevStation Technology was founded in 2020. Our company was awarded the
        Best Startup award in the Yemeni Business Club competition and was
        distinguished by its start in professional interface design (UX\UI). We
        have worked with many companies and leading entities locally and
        internationally. Having the energy of youth makes us seek a technical
        renaissance that serves the community in developing their businesses and
        facilitating access to their services.
      </p>
    </div>
    <div class="col-lg-4 text-center order-lg-2 order-2">
      <img
        src="../../assets/images/spaceman.webp"
        alt="SPACEMAN1"
        class="about-section-image"
        style="height: 100%"
      />
      <!-- <person-icon /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {};
  },
  methods: {},
};
</script>