<template>
  <div
    class="
      row
      no-gutters
      about-section
      services-section
      align-items-center
      services-animation3
    "
  >
    <div class="col-lg-8 position-relative mb-0 order-lg-1 order-1">
      <!-- <h1 class="services-section-index">3</h1> -->
      <h1>Designing user experiences and user interfaces (UX\UI)</h1>
      <!-- <div class="dev-scroll"> -->
      <p>
        Our expertise in User Experience Design (UX) has enabled us to simplify
        and enhance the interaction between our clients’ target customers and
        their digital presence. Whether it is on a website or mobile
        application, our designs offer a unique digital experience. Our UX has
        enabled our clients to create compelling digital media presences,
        spurring customer loyalty and improving brand value.
      </p>
      <!-- </div> -->
    </div>
    <div
      class="col-lg-4 order-lg-2 order-2 text-center"
      style="perspective: 600px"
    >
      <img
        src="../../assets/images/services/uxui.webp"
        alt="Designing user experiences and user interfaces (UX\UI)"
        class="services-section-image w-75"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "UXUI",
  data() {
    return {};
  },
  methods: {},
};
</script>